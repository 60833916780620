import React, {createContext, useContext, useState, useEffect} from 'react';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import {useCookies} from 'react-cookie';


export const RestContext = React.createContext();

const loadingReducer = (state, action) => {
	switch(action.type) {
		case 'LOADING':
			return {...state, loading: true};
		case 'LOADED':
			return {...state, loading: false};
	}
};


const LoaderContainer = (props) => {
	return <div style={{/*position: 'absolute',*/
			left: 0, top: 0, right: 0, bottom: 0,
			display: 'flex', justifyContent: 'center',
			alignItems: 'center', position: 'fixed',
			background: '#7f7f7f44', zIndex: 199999,
		}}>
		<CircularProgress/>
	</div>
};

export const RestContextProvider = ({children}) => {
	const [loading, setLoading] = useState(false);
	const [mensaje, setMensaje] = useState({mensaje: '', titulo: '', tipo: '', visible: false});
	const mensajeErrorGeneral = 'Se produjo un error al momento de efectuar la solicitud';
    const [cookies] = useCookies(['token']);

	const getToken = () => {
		 const cname= 'token';
		 let name = cname + "=";
		 let decodedCookie = decodeURIComponent(document.cookie);
		 let ca = decodedCookie.split(';');
		 for(let i = 0; i <ca.length; i++) {
			let c = ca[i];
			while (c.charAt(0) == ' ') {
				c = c.substring(1);
			}
			if (c.indexOf(name) == 0) {
				return c.substring(name.length, c.length);
			}
		}
		return "";
	};

	const getTenant = () => {
		 const cname= 'tenant';
		 let name = cname + "=";
		 let decodedCookie = decodeURIComponent(document.cookie);
		 let ca = decodedCookie.split(';');
		 for(let i = 0; i <ca.length; i++) {
			let c = ca[i];
			while (c.charAt(0) == ' ') {
				c = c.substring(1);
			}
			if (c.indexOf(name) == 0) {
				return c.substring(name.length, c.length);
			}
		}
		return "";
	};


	const Mensaje = () => { //{{{
		return <Dialog open={mensaje.visible}>
				<DialogTitle>{mensaje.tipo=='E'?'Error': mensaje.titulo}</DialogTitle>
				<DialogContent style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
					{mensaje.tipo=='E' && <ErrorOutlineIcon style={{color: 'red', fontSize: '8rem' }}/>}
					<DialogContentText>
						{mensaje.mensaje}
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button variant="contained" onClick={(e) => {setMensaje({...mensaje, visible: false})}}>
						Aceptar
					</Button>
				</DialogActions>
			</Dialog>
	}; //}}}

    const handleFetch = ( fetchCall, onlyjson=false, mensaje='', mensajeError='' ) =>  { //{{{
        const timeout = window.setTimeout(() => {setLoading(true)}, 300);

        return new Promise( (resolve, reject) => {
            fetchCall
				.then(response => { //{{{
                    window.clearTimeout(timeout);
					setLoading(false);
					if(response.ok) {
                        if(mensaje) 
                            setMensaje({mensaje, titulo: 'Información', tipo: 'I', visible: true});

						if(onlyjson) {
							response.text().then(data => {
                                try {
                                    resolve(JSON.parse(data))
                                }
                                catch(err) {
                                    resolve(data);
                                }
                            });
                        }
                        else
                            resolve(response);
                    }
                    else {
						if(response.status === 401) {
							setMensaje({mensaje: 'El tiempo de sesión ha expirado', titulo: 'Error', tipo: 'E', visible: true});
							history('/');
						}
						else if(response.status === 404) {
							setMensaje({mensaje: 'URL no encontrado', titulo: 'Error', tipo: 'E', visible: true});
						}
						else {
                            if(mensajeError) {
								setMensaje({mensaje: mensajeError || mensajeErrorGeneral, titulo: 'Error', tipo: 'E', visible: true});
                                reject(response);
                            }
                            else {
                                response.text().then(data => {
                                    try {
                                        const msg = JSON.parse(data);
                                        console.log(msg);
                                        setMensaje({mensaje: msg?.detail || mensajeErrorGeneral, titulo: msg?.title || 'Error', tipo: 'E', visible: true});
                                    }
                                    catch {
                                        setMensaje({mensaje: data || mensajeError || mensajeErrorGeneral, titulo: 'Error', tipo: 'E', visible: true});
                                    }
                                    reject(response);
                                });
                            }
						}
                    }
                }) //}}}
                .catch(err => { //{{{
                    window.clearTimeout(timeout);
					setLoading(false);
                    setMensaje({mensaje: mensajeErrorGeneral, titulo: 'Error', tipo: 'E', visible: true});
                    reject(err);
                }) //}}}
        });
    }; //}}}

	const apiget = (url, onlyjson=false, mensaje='', mensajeError='') => { //{{{
		if(!url || url[0!='/']) throw new Error('La URL es inválida, debe comenzar con /');

        return handleFetch( 
			fetch(`${process.env.REACT_APP_ENDPOINT}${url}`, {headers: {Authorization: `Bearer ${getToken()}`, tenant: getTenant() }}),
                    onlyjson, mensaje, mensajeError,
        );
	}; //}}}

	const apipost = (url, data, onlyjson=false, sendHeaders={},  mensaje='', mensajeError='') => { //{{{
		if(!url || url[0!='/']) throw new Error('La URL es inválida, debe comenzar con /');
		const headers = {'Content-Type': 'application/json',
						 Authorization: `Bearer ${getToken()}`, tenant: getTenant(),
						  ...sendHeaders};
		if(sendHeaders['Content-Type'] === null)
			delete headers['Content-Type'];
		const body = headers['Content-Type']==='application/json'? JSON.stringify(data): data;

        return handleFetch(
			fetch(`${process.env.REACT_APP_ENDPOINT}${url}`, { method: 'POST', headers, body, }),
                    onlyjson, mensaje, mensajeError,
        );
	}; //}}}

	const apiput = (url, data, onlyjson=false, mensaje='', mensajeError='', sendHeaders={}) => { //{{{
		if(!url || url[0!='/']) throw new Error('La URL es inválida, debe comenzar con /');
		const headers = {
            Authorization: `Bearer ${getToken()}`, tenant: getTenant(),
			'Content-Type': 'application/json',
			...sendHeaders
		};

		let body = data;
		if(headers['Content-Type']==='application/json') {
			body = JSON.stringify(data);
		}
		else if(sendHeaders['Content-Type']===null) {
			delete(headers['Content-Type'])
		}

        return handleFetch(
			fetch(`${process.env.REACT_APP_ENDPOINT}${url}`, {method: 'PUT', headers, body}),
                    onlyjson, mensaje, mensajeError,
        );
	}; //}}}

    const apidelete = (url, onlyjson=false, sendHeaders={},  mensaje='', mensajeError='') => { //{{{
		if(!url || url[0!='/']) throw new Error('La URL es inválida, debe comenzar con /');
        const headers = {'Content-Type': 'application/json',
                         Authorization: `Bearer ${getToken()}`, tenant: getTenant(),
                         ...sendHeaders};
        if(sendHeaders['Content-Type'] === null) {
            delete headers['Content-Type'];
        }

        return handleFetch(
			fetch(`${process.env.REACT_APP_ENDPOINT}${url}`, { method: 'DELETE', headers, }),
                    onlyjson, mensaje, mensajeError,
        );
	}; //}}}

	const get = (url, onlyjson=false) => { //{{{
		setLoading(true);
		return new Promise(function(resolve, reject) {
			fetch(url, {headers: {Authorization: `Bearer ${getToken()}`, tenant: getTenant() }})
				.then(response => {
					setLoading(false);
					if(response.status === 401) {
						document.history.push(null, null, '/auth');
						//useNavigate('/auth');
						reject(response);
					}
					else {
						if(onlyjson) return response.json();
						resolve(response);
					}
				})
				.then(data => {
					resolve(data);
				})
				.catch(err => {
					setLoading(false);
					reject(err);
				});
		});
	}; //}}}

	const post = (url, data, headers, onlyjson=false, mensaje='', mensajeError='') => { //{{{
		setLoading(true);
		return new Promise(function(resolve, reject) {
			fetch(url, {
					method: 'POST',
					headers: headers || {},
				    body: JSON.stringify(data)
				})
				.then(response => {
					setLoading(false);
					if(response.status !== 200 && response.status !== 201) {
						reject(response);
					}
					else {
						if(onlyjson) return response.json();
						resolve(response);
					}
				})
				.then(data => {
					resolve(data);
				})
				.catch(err => {
					setLoading(false);
					reject(err);
				});
		});
	}; //}}}

	const value = {loading, setLoading, get, post, apiget, apipost, apiput, apidelete, getTenant };

	return	(<RestContext.Provider value={value}>
				{loading && <LoaderContainer/>}
				<Mensaje/>
				{children}
			</RestContext.Provider>)
}
