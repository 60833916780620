import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import * as Yup from 'yup';
import Button from '@mui/material/Button';
import logo from '../assets/logo120.jpg';
import Paper from '@mui/material/Paper';
import React from 'react';
import { RestContext } from '../contexts/RestContext';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import { useCookies } from 'react-cookie';
import { useNavigate, Navigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { setUsuario }  from '../actions';
import { useContext, useState, useEffect } from 'react';


function mapDispatchToProps(dispatch) {
  return {
    setUsuario: usuario => dispatch(setUsuario(usuario))
  }
}


const Login = (props) => {
    const [usuario, setUsuario] = useState('');
    const [clave, setClave] = useState('');
    const [pais, setPais] = useState('');
    const context = useContext(RestContext); 
    const [cookies, setCookies] = useCookies(['token', 'username', 'tenant']);
    const history = useNavigate();
    const paises = [
        {codigo: 'AR', nombre: 'Argentina'},
        {codigo: 'BO', nombre: 'Bolivia'},
        {codigo: 'CL', nombre: 'Chile'},
        {codigo: 'CO', nombre: 'Colombia'},
        {codigo: 'EC', nombre: 'Ecuador'},
        {codigo: 'PE', nombre: 'Perú'},
    ];

    const handleLogin = (e) => {
        context.post(`${process.env.REACT_APP_ENDPOINT}/auth`,
            {usuario, password: clave}, {
                'Content-Type': 'application/json', 'Accept': 'text/plain, application/json',
                'tenant': pais
            }, false).then(response => {
                return response.text();
            })
            .then(data => {
                setCookies('token', data);
                console.log(props);
                try {
                    const payload = JSON.parse(atob(data.split('.')[1]));
                    setCookies('username', payload['userid']);
                    setCookies('tenant', pais);
                    props.setUsuario({sub: payload['userid']});
                }
                catch(e) {
                    console.log(e);
                    setCookies('username', '(unknown)');
                }
                history('/sys');
            });
    };

    return <Paper style={{display: 'block', width: 200, margin: 'auto'}}>
            <Typography variant="h5">Kamayuq 2.0</Typography>
            <TextField value={usuario} label="Usuario" onChange={(e) => setUsuario(e.target.value)}/>
            <TextField value={clave} type="password" label="Clave" onChange={(e) => setClave(e.target.value)}/>
            <Select value={pais} label="Pais" onChange={(e) => setPais(e.target.value)} fullWidth={true}>
                {paises.map((x,i) =><MenuItem key={i} value={x.codigo}>{x.nombre}</MenuItem>)}
            </Select>
            <Button variant="contained" disabled={!(usuario && clave && pais)}
            fullWidth onClick={handleLogin}>Iniciar sesión</Button>
        </Paper>

};

export default connect(null, mapDispatchToProps)(Login);
