import { SET_USUARIO, MENSAJE, TITULO, LOGOUT }  from '../actions/types';

const initialState = {
	user: {
		sub: '',
		pps: [],
	},
	procesando: false,
	popup: {mensaje: '', tipo: ''}, //M: Mensaje, W: Warning, E: Error
};

function rootReducer(state = initialState, action) {
	if(action.type === SET_USUARIO) {
		return {...state, user: action.payload}
	}
	else if(action.type === TITULO) {
		return {...state, tituloModuloActual: action.payload};
	}
	else if(action.type === LOGOUT) {
		return {...initialState};
	}
	else if(action.type === MENSAJE) {
		return {...initialState, mensaje: action.payload};
	}
	return state;
}

export default rootReducer;
