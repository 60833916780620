import React from 'react';
import {Component} from 'react';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EventNoteIcon from '@mui/icons-material/EventNote';
import Avatar from '@mui/material/Avatar';
import NumberFormat from 'react-number-format';
import Typography from '@mui/material/Typography';
import { RestContext } from '../../../contexts/RestContext';
import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import AccordionDetails from '@mui/material/AccordionDetails';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { connect } from 'react-redux';
import { parseISO, format } from 'date-fns';

const mapStateToProps = (state) => {
  return { pps: state.user.pps }
};


function withParams(Component: Component) {
	const p = props => <Component {...props} params={useParams()} />;
	p.displayName="xxx";

	return p;
}


var map;
var marker;
var google;
var infoWindow;
var miPosicion;
const capas = {
        limitesDistritales: null,
        limitesProvinciales: null,
        cartasNacionales: null,
        labelsDistritales: null,
        labelsProvinciales: null,
        labelsCartasNacionales: null
};

class AmenazaInfo extends React.Component {
    googleMapsKey = 'AIzaSyBsBAzIQNk60_d0eo8J8rBx4G-J4fnn5kg';
    mapRef = React.createRef();

	constructor(props) {
		super(props);
		this.state = {amenaza: {}, semestres: [], seleccionado: 0, avanceAcademico: [],
			dialogoPersonales: false, verObservaciones: false,
			dialogoReniec: false, datosReniec: {},
			amenazaObservacion: {}, puedeEditar: (props.pps || []).includes('ACAD000003'),
			amenazaEscalaPagos: {}, detalleEscalaPagosOpen: false,
		};
        this.descargarFicha = this.descargarFicha.bind(this);
        this.descargarExcel = this.descargarExcel.bind(this);
        this.initMap = this.initMap.bind(this);
        this.cargarDatos = this.cargarDatos.bind(this);
	}



	componentDidMount() {
        this.cargarDatos();
        const callbackName = 'mapReady';
        const url = `https://maps.googleapis.com/maps/api/js?key=${this.googleMapsKey}&callback=${callbackName}`;
        if(document.querySelector(`script[src='${url}]'`) === null) {
            const script = document.createElement('script');
            script.async = true;
            script.src = url;
            window[callbackName] =  () => {
                google = window.google;
                map = new window.google.maps.Map(this.mapRef.current, {
                    zoom: 9,
                    center: {lat: this.props.amenaza.latitud, lng: this.props.amenaza.longitud},
                    streetViewControl: false,
                    mapTypeId: 'satellite',
                    mapTypeControlOptions: {
                        style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
                        position: google.maps.ControlPosition.BOTTOM_RIGHT,
                    },
                });
                this.initMap();
                infoWindow = new google.maps.InfoWindow({content: ''});
            };
            document.head.appendChild(script);
        }
	}

    cargarDatos() {
        this.context.apiget(`/amenazas/info/${this.props.amenaza.id}`, true).then(data => {
            this.setState({amenaza: data});
        });
    }

    descargarExcel() {
        this.context.apiget(`/amenazas/${this.props.amenaza.id}/ficha/excel`, false).then(response => {
            const filename = 'ficha1.xlsx';
            response.blob().then(data => {
                var a = window.document.createElement('a');
                a.href = window.URL.createObjectURL(data);
                a.setAttribute('target', '_blank');
                a.download = filename;
                document.body.appendChild(a);
                a.click();
                a.remove();
            });
        });
    }

    descargarFicha() {
        this.context.apiget(`/amenazas/${this.props.amenaza.id}/ficha`, false).then(response => {
            const filename = 'ficha1.pdf';
            response.blob().then(data => {
                var a = window.document.createElement('a');
                a.href = window.URL.createObjectURL(data);
                a.setAttribute('target', '_blank');
                a.download = filename;
                document.body.appendChild(a);
                a.click();
                a.remove();
            });
        });
    }

    initMap() {
        marker = new window.google.maps.Marker({
            position: {
                lat: this.props.amenaza.latitud,
                lng: this.props.amenaza.longitud,
            },
        });
        marker.setMap(map);
    }


	render() {

	return (<Dialog open={this.props.open} onClose={this.props.onClose}
        fullWidth={true} maxWidth="sm" sx={{maxHeight: 600}}>
        <DialogTitle>Información del registro</DialogTitle>
        <DialogContent>
            <Grid container spacing={2}> {/*{{{*/}
                <Grid item xs={7}> {/*{{{*/}
                    <Grid item container spacing={2}> {/*{{{*/}
                        <Grid item xs={6}>
                            <Typography variant="caption" >
                                Id
                            </Typography>
                            <Typography variant="body1">
                                {this.props.amenaza.id}
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant="caption" >
                                Fecha
                            </Typography>
                            <Typography variant="body1">
                                {
                                    this.state.amenaza.fechaRegistro?
                                    format(parseISO(this.state.amenaza.fechaRegistro), 'dd/MM/yyy')
                                    : '--'
                                }
                            </Typography>
                        </Grid>
                    </Grid> {/*}}}*/}
                    <Grid item container spacing={2}> {/*{{{*/}
                        <Grid item xs={6}>
                            <Typography variant="caption" >
                                Latitud
                            </Typography>
                            <Typography variant="body1">
                                {this.props.amenaza.latitud}
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant="caption" >
                                Longitud
                            </Typography>
                            <Typography variant="body1">
                                {this.props.amenaza.longitud}
                            </Typography>
                        </Grid>
                    </Grid> {/*}}}*/}
                    <Typography variant="caption" >
                        Origen
                    </Typography>
                    <Typography variant="body2">
                        {this.state.amenaza.origenNombre}
                    </Typography>
                    <Typography variant="caption">
                        Tipo
                    </Typography>
                    <Typography variant="body2">
                        {this.state.amenaza.tipoNombre}
                    </Typography>
                    <Typography variant="caption">
                        Evento
                    </Typography>
                    <Typography variant="body2">
                        {this.state.amenaza.eventoNombre}
                    </Typography>
                </Grid>
                <Grid item xs={5} ref={this.mapRef} >
                </Grid>
            </Grid> {/*}}}*/}
            <Typography variant="caption">Area</Typography>
            <Typography variant="body2">
                {this.state.amenaza.area} m<sup>2</sup>
            </Typography>

            <Typography variant="caption">Recurrencia</Typography>
            <Typography variant="body2">
                {this.state.amenaza.recurrenciaNombre}
            </Typography>

            <Typography variant="caption">Daños</Typography>
            <Typography variant="body2">
                {this.state.amenaza.danioNombre}
            </Typography>

            <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                    <Typography variant="caption">Caracteristicas</Typography>
                    <Typography variant="body2">
                        {this.state.amenaza.caracteristicas}
                    </Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Typography variant="caption">Condicionantes</Typography>
                    <Typography variant="body2">
                        {this.state.amenaza.condicionantes}
                    </Typography>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Typography variant="caption">Desencadenantes</Typography>
                    <Typography variant="body2">
                        {this.state.amenaza.desencadenantes}
                    </Typography>
                </Grid>
            </Grid> {/*}}}*/}

            <Typography variant="caption">Descripción</Typography>
            <Typography variant="body2">
                {this.state.amenaza.descripcion}
            </Typography>

            {!!this.state.amenaza?.fotografias_?.length && <>
                <Typography variant="h6">Fotografías</Typography>
                {this.state.amenaza.fotografias_.map((x,i) => <Card key={i}>
                    <Box sx={{display: 'flex'}}>
                        <CardMedia component="img" sx={{width: 64}} image={`data:image/jpg;base64,${x.data}`} />
                        <CardContent sx={{flex: '1'}}>
                            <Typography variant="body1">{x.descripcion}</Typography>
                            {/*
                            <Typography variant="caption">
                                {x.latitud},{x.longitud}
                            </Typography>
                            */}
                        </CardContent>
                    </Box>
                </Card>
                )}
            </>}
        </DialogContent>
        <DialogActions>
            <Button onClick={(e) => this.descargarExcel() }>
                Descargar excel
            </Button>
            <Button onClick={(e) => this.descargarFicha() } variant="contained">
                Descargar ficha
            </Button>
            <Button onClick={(e) => this.props.onClose() }>
                Cerrar
            </Button>
        </DialogActions>
	</Dialog>)
	}
}

AmenazaInfo.contextType = RestContext;
export default connect(mapStateToProps)(withParams(AmenazaInfo));
