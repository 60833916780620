import React from 'react';
import AddIcon from '@mui/icons-material/Add';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { DataGrid, esES, GridToolbar } from '@mui/x-data-grid';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import Fab from '@mui/material/Fab';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import List from '@mui/material/List';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import NumberFormat from 'react-number-format';
import { RestContext } from '../../../../contexts/RestContext';
import Select from '@mui/material/Select';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';


class Editor extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            titulo: props.data?.id? `Editando registro ${props.data.id}`: 'Nuevo registro',
            id: 0, nombre: props.data?.nombre || '',
            orden: props.data?.orden || '',
            dimension: props.data?.dimension || '',
            dimensiones: [],
        };
        this.handleOnGuardar = this.handleOnGuardar.bind(this);
        this.handleOnCancelar = this.handleOnCancelar.bind(this);
    }

    componentDidMount() {
        this.context.apiget('/vulnerabilidades/factores/valores/editor', true).then(data => {
            this.setState({dimensiones: data[0].valores_});
        });
    }

    handleOnGuardar() {
        if(!window.confirm('Se va a guardar el registro, proceder?')) return;
        const payload = {
            id: this.props?.data?.id || 0,
            nombre: this.state.nombre,
            orden: this.state.orden,
            dimension: this.state.dimension,
        };
        this.context.apipost('/vulnerabilidades/factores', payload, true, {},
            'Registro guardado', 'Se produjo un error al guardar').then(data => {
                this.props.onClose(true);
            });
    }

    handleOnCancelar() {
        this.props.onClose();
    }

    render() { //{{{
        return <Dialog open={this.props?.open} onClose={this.props?.onClose}>
                <DialogTitle>{this.props.data?.id? `Editando registro ${this.props.data.id}`: 'Nuevo registro'}</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2} sx={{mt: 1, mb: 1}}> {/*{{{ Grid de datos */}
                        <Grid item xs={12} md={8}>
                            <TextField value={this.state.nombre}
                                onChange={(e) => {this.setState({nombre: e.target.value})} }
                                label="Nombre" size="small" fullWidth/>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <TextField value={this.state.orden}
                                onChange={(e) => {this.setState({orden: e.target.value})} }
                                label="Orden" size="small" fullWidth/>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth={true}>                
                                <InputLabel>Dimensión</InputLabel>
                                <Select value={this.state.dimension} label="Dimensión" size="small"
                                    onChange={(e) => {this.setState({dimension: e.target.value})} } >
                                    {this.state.dimensiones.map((x,i) => <MenuItem key={i} value={x.codigo}>
                                        {x.nombre}
                                        </MenuItem>
                                    )}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid> {/*}}}*/}
                </DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={this.handleOnGuardar}>
                        Guardar
                    </Button>
                    <Button onClick={this.handleOnCancelar}>
                        Cancelar
                    </Button>
                </DialogActions>
            </Dialog>
    } //}}}
}

Editor.contextType = RestContext;
export default Editor;
