import React from 'react';
import AddIcon from '@mui/icons-material/Add';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { DataGrid, esES, GridToolbar } from '@mui/x-data-grid';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import Fab from '@mui/material/Fab';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import List from '@mui/material/List';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import NumberFormat from 'react-number-format';
import { RestContext } from '../../../../contexts/RestContext';
import Select from '@mui/material/Select';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';


class Editor extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            titulo: props.data?.id? `Editando registro ${props.data.id}`: 'Nuevo registro',
            id: 0, nombre: props.data?.nombre || '',
            latitudNorte: props.data?.latitudNorte || '',
            longitudNorte: props.data?.longitudNorte || '',
            latitudSur: props.data?.latitudSur || '',
            longitudSur: props.data?.longitudSur || '',
        };
        this.handleOnGuardar = this.handleOnGuardar.bind(this);
        this.handleOnCancelar = this.handleOnCancelar.bind(this);
    }

    componentDidMount() {
    }

    handleOnGuardar() {
        if(!window.confirm('Se va a guardar el registro, proceder?')) return;
        const payload = {
            id: this.props?.data?.id || 0,
            nombre: this.state.nombre,
            latitudNorte: this.state.latitudNorte,
            longitudNorte: this.state.longitudNorte,
            latitudSur: this.state.latitudSur,
            longitudSur: this.state.longitudSur,
        };
        this.context.apipost('/mapas-offline', payload, true, {},
            'Registro guardado', 'Se produjo un error al guardar').then(data => {
                this.props.onClose(true);
            });
    }

    handleOnCancelar() {
        this.props.onClose();
    }

    render() { //{{{
        return <Dialog open={this.props?.open} onClose={this.props?.onClose}>
                <DialogTitle>{this.props.data?.id? `Editando registro ${this.props.data.id}`: 'Nuevo registro'}</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2} sx={{mt: 1, mb: 1}}> {/*{{{ Grid de datos */}
                        <Grid item xs={12}>
                            <TextField value={this.state.nombre}
                                onChange={(e) => {this.setState({nombre: e.target.value})} }
                                label="Nombre" size="small" fullWidth/>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField value={this.state.latitudNorte}
                                onChange={(e) => {this.setState({latitudNorte: e.target.value})} }
                                label="Latitud norte" size="small" fullWidth/>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField value={this.state.longitudNorte}
                                onChange={(e) => {this.setState({longitudNorte: e.target.value})} }
                                label="Longitud norte" size="small" fullWidth/>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField value={this.state.latitudSur}
                                onChange={(e) => {this.setState({latitudSur: e.target.value})} }
                                label="Latitud sur" size="small" fullWidth/>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField value={this.state.longitudSur}
                                onChange={(e) => {this.setState({longitudSur: e.target.value})} }
                                label="Longitud sur" size="small" fullWidth/>
                        </Grid>
                    </Grid> {/*}}}*/}
                </DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={this.handleOnGuardar}>
                        Guardar
                    </Button>
                    <Button onClick={this.handleOnCancelar}>
                        Cancelar
                    </Button>
                </DialogActions>
            </Dialog>
    } //}}}
}

Editor.contextType = RestContext;
export default Editor;
