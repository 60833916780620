import React from 'react';
import AddIcon from '@mui/icons-material/Add';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Button from '@mui/material/Button';
import { DataGrid, esES, GridToolbar } from '@mui/x-data-grid';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import NumberFormat from 'react-number-format';
import { RestContext } from '../../../../contexts/RestContext';
import Select from '@mui/material/Select';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import * as Yup from 'yup';
import { Formik, getIn } from 'formik';


class Editor extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            titulo: props.data?.id? `Editando registro ${props.data.id}`: 'Nuevo registro',
            initialValues: {
                login: '', email: '', nombresRazonSocial: '', apellidoPaterno: '', apellidoMaterno: '',
                direccion: '',  password: '', repassword: '', perfiles: [],
            },
            id: 0, usuario: {},  perfiles_: [], tiposDocumentoIdentidad: [],
        };
        this.handleOnGuardar = this.handleOnGuardar.bind(this);
        this.handleOnCancelar = this.handleOnCancelar.bind(this);
    }

    componentDidMount() {
        if(this.props.data?.id) {
            this.context.apiget(`/usuarios/${this.props.data.id}`, true).then(data => {
                this.setState(
                    {
                        initialValues: {...data,
                            perfiles: (data.perfiles_ || []).map((x)=>x.codigo)
                        }
                    }
                );
                this.forceUpdate();
            }, () => {
            });
        }
        this.context.apiget('/usuarios/valores/editor', true).then(data => {
            this.setState({
                tiposDocumentoIdentidad: (data.find(x => x.codigo=='TDI')?.valores_ || []),
                perfiles_: (data.find(x => x.codigo=='PRF')?.valores_ || []).map((x,i) => (
                    {codigo: x.codigo, nombre: x.nombre}
                ))
            }, () => {
            });
        });
    }

    handleOnGuardar(values) {
        if(!window.confirm('Desea registrar el usuario?')) return;
        const payload = {...values, perfiles_: values.perfiles.map((x,i) => ({codigo: x}))  };
        this.context.apipost('/usuarios', payload, true, {},
            'Registro guardado', 'Se produjo un error al guardar').then(data => {
                this.props.onClose(true);
            });
    }

    handleOnCancelar() {
        this.props.onClose();
    }

    render() { //{{{


        const validationSchema = Yup.object().shape({
            login: Yup.string().min(5, 'Mìnimo 5 caracteres').required('Requerido'),
            email: Yup.string().email('Correo electrónico no válido'),
            nombresRazonSocial: Yup.string().required('Ingrese su nombre'),
            apellidoPaterno: Yup.string().required('Ingrese su apellido paterno').nullable(),
            apellidoMaterno: Yup.string().nullable(),
            direccion: Yup.string().required('Ingrese una dirección').nullable(),
            password: Yup.string(),
            repassword: Yup.string().oneOf([Yup.ref('password'), null], 'Las passwords no coinciden'),
            perfiles: Yup.array().required('Seleccionar un perfil'),
        });
        /*
                    validationSchema={Yup.object().shape({
                        login: Yup.string().required('Login requerido'),
                        email: Yup.string().email('Correo electrónico no válido').required('Correo electrónico requerido'),
                    })}*/

        return <Dialog open={this.props?.open} onClose={this.props?.onClose} fullWidth={true} maxWidth="sm">
                <DialogTitle>{this.props.data?.id? `Editando registro ${this.props.data.id}`: 'Nuevo registro'}</DialogTitle>
                <React.Fragment>
                    <Formik initialValues={this.state.initialValues} validationSchema={validationSchema}
                        enableReinitialize={true}
                        onSubmit={this.handleOnGuardar}>
                        {({
                            errors, handleBlur, handleChange, handleSubmit, isSubmitting,
                            isValid, dirty, touched, values
                        }) => (
                        <form autoComplete="off" noValidate onSubmit={handleSubmit}>
                            <DialogContent>
                                <Grid container spacing={2} sx={{mt: 1, mb: 1}}> {/*{{{ Grid de datos */}
                                    <Grid item xs={12} sm={4}>
                                        <TextField name="login" type="text" required
                                            value={values.login}
                                            label="Login de usuario" size="small" fullWidth
                                            onChange={handleChange} onBlur={handleBlur}
                                            error={Boolean(touched.login && errors.login)}
                                            helperText={touched.login && errors.login}
                                            />
                                    </Grid>
                                    <Grid item xs={12} sm={8}>
                                        <TextField name="email" required
                                            value={values.email}
                                            label="Email" size="small" fullWidth
                                            onChange={handleChange} onBlur={handleBlur}
                                            error={Boolean(touched.email && errors.email)}
                                            helperText={touched.email && errors.email}
                                            />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField name="nombresRazonSocial" required
                                            value={values.nombresRazonSocial}
                                            label="Nombres" size="small" fullWidth
                                            onChange={handleChange} onBlur={handleBlur}
                                            error={Boolean(touched.nombresRazonSocial && errors.nombresRazonSocial)}
                                            helperText={touched.nombresRazonSocial && errors.nombresRazonSocial}
                                            />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField name="apellidoPaterno" required
                                            value={values.apellidoPaterno}
                                            label="Apellido paterno" size="small" fullWidth
                                            onChange={handleChange} onBlur={handleBlur}
                                            error={Boolean(touched.apellidoPaterno && errors.apellidoPaterno)}
                                            helperText={touched.apellidoPaterno && errors.apellidoPaterno}
                                            />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField name="apellidoMaterno"
                                            value={values.apellidoMaterno}
                                            label="Apellido materno" size="small" fullWidth
                                            onChange={handleChange} onBlur={handleBlur}
                                            error={Boolean(touched.apellidoMaterno && errors.apellidoMaterno)}
                                            helperText={touched.apellidoMaterno && errors.apellidoMaterno}
                                            />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField name="direccion" required
                                            value={values.direccion}
                                            label="Dirección" size="small" fullWidth
                                            onChange={handleChange} onBlur={handleBlur}
                                            error = {Boolean(touched.direccion && errors.direccion)}
                                            helperText={touched.direccion && errors.direccion}
                                            />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField name="password" type="password"
                                            value={values.password}
                                            label="Contraseña" size="small" fullWidth
                                            onChange={handleChange} onBlur={handleBlur}
                                            error = {Boolean(touched.password && errors.password)}
                                            helperText={touched.password && errors.password}
                                            />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField name="repassword" type="password"
                                            value={values.repassword}
                                            label="Repetir contraseña" size="small" fullWidth
                                            onChange={handleChange} onBlur={handleBlur}
                                            error = {Boolean(touched.password && errors.repassword)}
                                            helperText={touched.repassword && errors.repassword}
                                            />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl fullWidth>
                                            <InputLabel>Perfiles</InputLabel>
                                            <Select name="perfiles" multiple
                                                value={values.perfiles}
                                                label="Perfiles" size="small" fullWidth
                                                onChange={handleChange} onBlur={handleBlur}
                                                error = {Boolean(touched.perfiles && errors.perfiles)}
                                                helperText={touched.perfiles && errors.perfiles}
                                            >
                                                {this.state.perfiles_.map((x,i) => <MenuItem key={i} value={x.codigo}>
                                                    {x.nombre}
                                                </MenuItem>)}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid> {/*}}}*/}
                            </DialogContent>
                            <DialogActions>
                                <Button color="primary" type="submit" disabled={!isValid}>
                                    Guardar
                                </Button>
                                <Button onClick={this.handleOnCancelar}>
                                    Cancelar
                                </Button>
                            </DialogActions>
                        </form>
                    )}
                    </Formik>
                </React.Fragment>
            </Dialog>
    } //}}}
}

Editor.contextType = RestContext;
export default Editor;
