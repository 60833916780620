import React from 'react';
import AddIcon from '@mui/icons-material/Add';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { DataGrid, esES, GridToolbar } from '@mui/x-data-grid';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import Fab from '@mui/material/Fab';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputLabel from '@mui/material/InputLabel';
import List from '@mui/material/List';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import NumberFormat from 'react-number-format';
import { RestContext } from '../../../../contexts/RestContext';
import Select from '@mui/material/Select';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Autocomplete from '@mui/material/Autocomplete';


class Editor extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            titulo: props.data?.id? `Editando registro ${props.data.id}`: 'Nuevo registro',
            id: 0, nombre: props.data?.nombre || '',
            orden: props.data?.orden || '',
            factor: props.data?.factor?
                {id: props.data?.factor, nombre: `id: ${props.data?.factor}`}: null,
            factorNombre: '',
            multiple: props.data?.multiple || false,
            factores: [],
        };
        this.handleOnGuardar = this.handleOnGuardar.bind(this);
        this.handleOnCancelar = this.handleOnCancelar.bind(this);
    }

    componentDidMount() {
        /*
        this.context.apiget('/vulnerabilidades/parametros/valores/editor', true).then(data => {
            this.setState({factores: data[0].valores_});
        });*/
         this.context.apiget('/vulnerabilidades/factores', true).then(data => {
            this.setState({
                factores: data,
                factor: this.state.factor? data.find(x => x.id == this.state.factor.id): null,
            });
        });
    }

    handleOnGuardar() {
        if(!window.confirm('Se va a guardar el registro, proceder?')) return;
        const payload = {
            id: this.props?.data?.id || 0,
            nombre: this.state.nombre,
            orden: this.state.orden,
            multiple: this.state.multiple,
            factor: this.state.factor.id,
        };
        this.context.apipost('/vulnerabilidades/parametros', payload, true, {},
            'Registro guardado', 'Se produjo un error al guardar').then(data => {
                this.props.onClose(true);
            });
    }

    handleOnCancelar() {
        this.props.onClose();
    }

    render() { //{{{
        return <Dialog open={this.props?.open} onClose={this.props?.onClose}>
                <DialogTitle>{this.props.data?.id? `Editando registro ${this.props.data.id}`: 'Nuevo registro'}</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2} sx={{mt: 1, mb: 1}}> {/*{{{ Grid de datos */}
                        <Grid item xs={12}>
                            <TextField value={this.state.nombre}
                                onChange={(e) => {this.setState({nombre: e.target.value})} }
                                label="Nombre" size="small" fullWidth/>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField value={this.state.orden}
                                onChange={(e) => {this.setState({orden: e.target.value})} }
                                label="Orden" size="small" fullWidth/>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormControlLabel
                                control={ <Switch checked={this.state.multiple}
                                    onChange={(e) => {this.setState({multiple: e.target.checked})} }
                                    size="small" fullWidth/> }
                                label="Múltiple" />
                        </Grid>
                        <Grid item xs={12}>
                            <Autocomplete options={this.state.factores} fullWidth={true}
                                value={this.state.factor}
                                inputValue={this.state.factorNombre}
                                getOptionLabel={e => e.nombre}
                                onChange={(e, nv) => {this.setState({factor: nv})}}
                                onInputChange={(e,v) => this.setState({factorNombre: v}) }
                                renderInput={params => <Box>
                                        <TextField {...params} label="Factor"
                                            placeholder="Factor" inputProps={{...params.inputProps}}  />
                                        <Typography variant="caption">
                                            {this.state.factor?.dimensionNombre}
                                        </Typography>
                                    </Box>
                                }
                                renderOption={(props, option)  => <Box key={option.id} {...props} component="li"
                                        style={{flexDirection: 'column', display: 'flex', alignItems: 'start'}}>
                                        <Typography variant="body1">{option.nombre}</Typography>
                                        <Typography variant="caption">{option.dimensionNombre}</Typography>
                                    </Box>
                                }
                            />
                            {/*
                            <FormControl fullWidth={true}>                
                                <InputLabel>Factor</InputLabel>
                                <Select value={this.state.factor} label="Dimensión" size="small"
                                    onChange={(e) => {this.setState({factor: e.target.value})} } >
                                    {this.state.factores.map((x,i) => <MenuItem key={i} value={x.id} style={{flexDirection:'column', alignItems: 'start'}}>
                                            <Typography variant="body1" style={{display: 'block'}}>
                                                {x.nombre}
                                            </Typography>
                                            <Typography variant="caption">
                                                {x.dimensionNombre}
                                            </Typography>
                                        </MenuItem>
                                    )}
                                </Select>
                            </FormControl>
                            */}
                        </Grid>
                    </Grid> {/*}}}*/}
                </DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={this.handleOnGuardar}>
                        Guardar
                    </Button>
                    <Button onClick={this.handleOnCancelar}>
                        Cancelar
                    </Button>
                </DialogActions>
            </Dialog>
    } //}}}
}

Editor.contextType = RestContext;
export default Editor;
