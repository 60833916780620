import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Switch from '@mui/material/Switch';
import {RestContext} from '../../../contexts/RestContext';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import {useContext, useState, useEffect} from 'react';
import esLocale from 'date-fns/locale/es';
import {format} from 'date-fns';

const OpcionesDescarga = (props) => {
    const context = useContext(RestContext);
    const [fechaInicio, setFechaInicio] = useState(new Date());
    const [fechaFin, setFechaFin] = useState(new Date());
    const [tipoReportePdf, setTipoReportePdf] = useState(0);
    const [incluirFotografias, setIncluirImagenes] = useState(true);

    const handleOnDescargarClick = (e) => {
        context.apiget(`/vulnerabilidades/descarga`+
            `?fecha_inicio=${format(fechaInicio, 'yyyy-MM-dd')}`+
            `&fecha_fin=${format(fechaFin, 'yyyy-MM-dd')}`+
            `&incluir_fotografias=${incluirFotografias}` +
            `&tipo_reporte_pdf=${tipoReportePdf}`, false).then(response => {
                if(!response.ok) return;
                const [, filename] = (response.headers['content-disposition'] ||'filename=reporte.zip').split('filename=');
                response.blob().then(data => {
                    var a = window.document.createElement('a');
                    a.href = window.URL.createObjectURL(data);
                    a.download = filename;
                    document.body.appendChild(a);
                    a.click();
                    a.remove();
                });
            });
    };

    return <Dialog open={props.open} onClose={props.onClose} sx={{h: 600}}>
            <DialogTitle>Opciones de descarga</DialogTitle>
            <DialogContent style={{padding: 10}} spacing={2}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={esLocale}>
                            <DatePicker sx={{mt: 5}} inputFormat="dd/MM/yyyy"
                                label="Fecha inicio"
                                value={fechaInicio}
                                onChange={(e) => { setFechaInicio(e); }}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </LocalizationProvider>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={esLocale}>
                            <DatePicker sx={{mt: 5}} inputFormat="dd/MM/yyyy"
                                label="Fecha fin"
                                value={fechaFin}
                                onChange={(e) => { setFechaFin(e); }}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </LocalizationProvider>
                    </Grid>
                </Grid>
                <Select value={tipoReportePdf} sx={{mt: 2, mb: 2}}
                    onChange={(e) => setTipoReportePdf(e.target.value)} fullWidth={true}>
                    <MenuItem value={0}>No incluir fichas</MenuItem>
                    <MenuItem value={1}>Fichas consolidadas</MenuItem>
                    <MenuItem value={2}>Fichas por separado</MenuItem>
                </Select>
                <FormControlLabel 
                    control={<Switch checked={incluirFotografias} 
                            onChange={(e) => setIncluirImagenes(e.target.checked) }
                    />} label="Incluir imágenes" />
            </DialogContent>
            <DialogActions>
                <Button variant="contained" onClick={(e) => handleOnDescargarClick(e)}>
                    Descargar
                </Button>
                <Button onClick={(e) => {props.onClose()} }>
                    Cerrar
                </Button>
            </DialogActions>
        </Dialog>

};

export default OpcionesDescarga;
