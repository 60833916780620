import React from 'react';

import AddIcon from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ClearIcon from '@mui/icons-material/Clear';
import { DataGrid, esES, GridToolbar } from '@mui/x-data-grid';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import Fab from '@mui/material/Fab';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import Paper from '@mui/material/Paper';
import { RestContext } from '../../../../contexts/RestContext';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Editor from './editor';

class Factor extends React.Component {
    constructor(props) {
        super(props);
        this.state = {filtro: '', rows: [], editorAbierto: false, registro: {}};
        this.handleOnChangeFiltro = this.handleOnChangeFiltro.bind(this);
        this.handleDialogo = this.handleDialogo.bind(this);
        this.handleCloseEditor = this.handleCloseEditor.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.cargarRegistros = this.cargarRegistros.bind(this);
        this.filtrar = this.filtrar.bind(this);
        this.allData = [];
        this.timeout = null;
        this.columns = [ //{{{
			{
				field: 'id',
				headerName: 'Id',
				width: 80
			},
			{
				field: 'nombre',
				headerName: 'Nombre',
				width: 400
			},
			{
				field: 'dimensionNombre',
				headerName: 'Dimensión',
				width: 400
			},
			{
				field: 'orden',
				headerName: 'Orden',
				width: 100
			},
			{
				field: 'tools',
				headerName: '',
				width: 50,
				renderCell: (params) => {
					return (<IconButton onClick={(e) => {
								this.handleDialogo(params.row)
							}}>
							<MoreHorizIcon/>
						</IconButton>);
				}
			},
			{
				field: 'delte',
				headerName: '',
				width: 50,
				renderCell: (params) => {
					return (<IconButton onClick={(e) => {
								this.handleDelete(params.row)
							}}>
							<DeleteOutlineIcon color="danger"/>
						</IconButton>);
				}
			},
        ]; //}}}
    }

    handleOnChangeFiltro(e) {
        this.setState({filtro: e.target.value});
        this.filtrar(e.target.value);
    }

    filtrar(valor) {
        const buscar = (valor || '').trim();
        if(this.timeout) window.clearTimeout(this.timeout);

        this.timeout = setTimeout(() => {
            this.setState({rows: buscar
                ? this.allData.filter(x => x.nombreMinuscula.includes(buscar))
                : this.allData
            });
        }, 500);
    }

    handleDelete(item) {
        if(!window.confirm('Eliminar este registro')) return;

        this.context.apidelete(`/vulnerabilidades/factores/${item.id}`, true, {}, 'Registro eliminado').then(data => {
            this.cargarRegistros();
        });
    }

    handleDialogo(item) {
        if(!item) {
            this.setState({editorAbierto: true, registro: {}});
        }
        else {
            this.context.apiget(`/vulnerabilidades/factores/${item.id}`, true).then(data => {
                this.setState({registro: data}, () => {
                    this.setState({editorAbierto: true});
                });
            });
        }
    }

    handleCloseEditor(data) {
        this.setState({editorAbierto: false});
        if(data) this.cargarRegistros();
    }

    cargarRegistros() {
        this.context.apiget('/vulnerabilidades/factores', true).then(data => {
            this.allData = data.map((x,i) =>({...x, nombreMinuscula: x.nombre.toLowerCase()}));
            this.setState({rows: this.allData});
        });
    }

    componentDidMount() {
        this.cargarRegistros();
    }

    render() {
		return <Box style={{display: 'flex', flexDirection: 'column', flex: 1}} spacing={1}>
                {this.state.editorAbierto && <Editor open={this.state.editorAbierto} onClose={this.handleCloseEditor} data={this.state.registro}/>}
    			<Typography variant="h4">Factores</Typography>
                <Paper sx={{mt: 1, p: 1}}  style={{display: 'flex', flexDirection: 'column', marginTop: 40, flex: 1}}>
                    <TextField variant="outlined" fullWidth={true} value= {this.state.filtro} label="Buscar"
                        onChange={this.handleOnChangeFiltro}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">
                                    <IconButton onClick={(e) => {this.filtrar('')} }>
                                        <ClearIcon/>
                                    </IconButton>
                                </InputAdornment>
                        }}
                    />
                    <DataGrid style={{flex: 1}} sx={{mt: 1}} columns={this.columns} rows={this.state.rows}/>
                    <Fab onClick={()=>{this.handleDialogo() }} color="primary" style={{right: 20, bottom: 60, position: 'absolute'}}>
                        <AddIcon/>
                    </Fab>
                </Paper>
            </Box>;
    }
}
Factor.contextType = RestContext;

export default Factor;

