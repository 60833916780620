import React from 'react';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { connect } from 'react-redux';

const mapStateToProps = (state) => {
  return { pps: state.user?.pps || [] }
};

const Procesos = connect(mapStateToProps)( (props) => {
	return <Paper style={{backgroundColor: 'rgba(255, 255, 255, 0.65)', flex: 1, overflow: 'auto'}} sx={{p: 2}}>
                <Typography variant="h4" sx={{mb: 4}}>Catálogos</Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={4} xl={4}>
                        <Card>
                            <CardContent>
                                <Typography variant="h5">
                                    Tipos de amenaza
                                </Typography>
                            </CardContent>
                            <CardActions>
                                <Button href="/sys/catalogos/tipos-amenaza">
                                    Mostrar
                                </Button>
                            </CardActions>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={4} xl={4}>
                        <Card>
                            <CardContent>
                                <Typography variant="h5">
                                    Daños
                                </Typography>
                            </CardContent>
                            <CardActions>
                                <Button href="/sys/catalogos/danios">
                                    Mostrar
                                </Button>
                            </CardActions>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={4} xl={4}>
                        <Card>
                            <CardContent>
                                <Typography variant="h5">
                                    Tipos de evento
                                </Typography>
                            </CardContent>
                            <CardActions>
                                <Button href="/sys/catalogos/tipos-evento">
                                    Mostrar
                                </Button>
                            </CardActions>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={4} xl={4}>
                        <Card>
                            <CardContent>
                                <Typography variant="h5">
                                    Factores
                                </Typography>
                            </CardContent>
                            <CardActions>
                                <Button href="/sys/catalogos/factores">
                                    Mostrar
                                </Button>
                            </CardActions>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={4} xl={4}>
                        <Card>
                            <CardContent>
                                <Typography variant="h5">
                                    Parámetros
                                </Typography>
                            </CardContent>
                            <CardActions>
                                <Button href="/sys/catalogos/parametros">
                                    Mostrar
                                </Button>
                            </CardActions>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={4} xl={4}>
                        <Card>
                            <CardContent>
                                <Typography variant="h5">
                                    Descriptores
                                </Typography>
                            </CardContent>
                            <CardActions>
                                <Button href="/sys/catalogos/descriptores">
                                    Mostrar
                                </Button>
                            </CardActions>
                        </Card>
                    </Grid>
                    {true &&
                    <Grid item xs={12} md={4} xl={4}>
                        <Card>
                            <CardContent>
                                <Typography variant="h5">
                                    Mapas offline
                                </Typography>
                            </CardContent>
                            <CardActions>
                                <Button href="/sys/catalogos/mapas-offline">
                                    Mostrar
                                </Button>
                            </CardActions>
                        </Card>
                    </Grid>}
                </Grid>
    		</Paper>;
});

export default Procesos;
