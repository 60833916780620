import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { CacheProvider } from '@emotion/react';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
//import { createEmotionCache } from '../utils/create-emotion-cache';
import { theme } from './theme';
import { BrowserRouter } from "react-router-dom";
import esLocale from 'date-fns/locale/es';
import { CookiesProvider } from 'react-cookie';
import { Provider } from 'react-redux';
import { RestContextProvider } from './contexts/RestContext'; 
import store from './store';


ReactDOM.render(
    <React.StrictMode>
      <LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale}>
        <CookiesProvider>
          <ThemeProvider theme={theme}>
            <CssBaseline />
              <BrowserRouter>
                <Provider store={store}>
                  <RestContextProvider>
                    <App/>
                  </RestContextProvider>
                </Provider>
              </BrowserRouter>
          </ThemeProvider>
        </CookiesProvider>
      </LocalizationProvider>
    </React.StrictMode>,
    document.getElementById('root')
);
