import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import ErrorIcon from '@mui/icons-material/Error';

function NotFound() {
	return <Paper sx={{height: 200, p: 2}}>
		<Typography variant="h4" style={{my: 20}}>
			<ErrorIcon color="warn" style={{fontSize: '4rem'}}/> Página no encontrada
		</Typography>
		<Typography variant="body1">
			El recurso solicitado no se encuentra disponible, en caso de tratarse de un error comuníquese con el administrador del sistema.
		</Typography>
	</Paper>
}

export default NotFound;
