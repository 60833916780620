import Typography from '@mui/material/Typography';


const PrivacyPolicy = props => <div>
        <Typography variant="h2">Política de privacidad</Typography>
        <Typography variant="h3">Qué datos personales recogemos y por qué los recogemos</Typography>
        <Typography variant="body">
        Recopilamos datos mediante el aplicativo kamayuq tales como imágenes referenciales, comentarios, geolocalización y la dirección IP de los usuarios.<br/>
        Los usuarios requieren ser registrados en nuestras bases de datos para lo cual deben proporcionar datos como nombres y documentos de identidad.
        </Typography>
        <Typography variant="h3">Dónde enviamos tus datos</Typography>
        <Typography variant="body">
        Los datos recopilados son usados para ser asociada la información recogida por el aplicativo kamayuq
        </Typography>
    </div>

export default PrivacyPolicy;
