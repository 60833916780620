import React from 'react';

import AddIcon from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ClearIcon from '@mui/icons-material/Clear';
import { DataGrid, esES, GridToolbar } from '@mui/x-data-grid';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import Fab from '@mui/material/Fab';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import Paper from '@mui/material/Paper';
import { RestContext } from '../../../contexts/RestContext';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import {parseISO, format} from 'date-fns';
import {useNavigate} from 'react-router-dom';
import VulnerabilidadInfo from './info';
import OpcionesDescarga from './OpcionesDescarga';


class Vulnerabilidades extends React.Component {
    constructor(props) {
        super(props);
        this.state = {filtro: '', rows: [], editorAbierto: false, registro: {}, infoVisible: false,
                        opcionesDescargaVisible: false, vulnerabilidadSeleccionada: {}};
        this.handleOnChangeFiltro = this.handleOnChangeFiltro.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.cargarRegistros = this.cargarRegistros.bind(this);
        this.seleccionarVulnerabilidad = this.seleccionarVulnerabilidad.bind(this);
        this.filtrar = this.filtrar.bind(this);
        this.allData = [];
        this.timeout = null;
        this.columns = [ //{{{
			{
				field: 'id',
				headerName: 'Id',
				width: 70
			},
			{
				field: 'fechaRegistro',
				headerName: 'Fecha',
				width: 100,
                renderCell: (params) => {
                    return <span>
                            {format(parseISO(params.row.fechaRegistro), 'dd/MM/yyyy') }
                            <Typography variant="caption" component="div">
                                {format(parseISO(params.row.fechaRegistro), 'HH:mm:ss')}
                            </Typography>
                        </span>
                }
			},
			{
				field: 'dimensionNombre',
				headerName: 'Dimensión',
				width: 240
			},
			{
				field: 'factorNombre',
				headerName: 'Factor',
				width: 180
			},
			{
				field: 'tipoPatrimonioNombre',
				headerName: 'Tipo de patrimonio',
				width: 180
			},
            /*
			{
				field: 'factorNombre',
				headerName: 'Factor',
				width: 250,
                renderCell: (params) => <span>
                        <Typography variant="caption" component="div">
                            {params.row.factorNombre}
                        </Typography>
                        <Typography variant="caption" component="div">
                            {params.row.dimensionNombre}
                        </Typography>
                        <Typography variant="caption" component="div">
                            Tipo: {params.row.tipoPatrimonioNombre}
                        </Typography>
                    </span>
			},
            */
			{
				field: 'caracteristicasBien',
				headerName: 'Características',
				width: 180
			},
			{
				field: 'descripcion',
				headerName: 'Descripción',
				width: 180
			},
            {
                field: '',
                headerName: 'Ubicación',
                width: 150,
                renderCell: (params) => <Box>
                        <Typography variant="caption" component="p">
                            E: {params.row.este} -&nbsp;
                            N: {params.row.norte} -&nbsp;
                            Z: {params.row.zonaNumero}{params.row.zonaLetra}
                        </Typography>
                        <Typography variant="caption" component="p">
                            Lat: {params.row.latitud}, Lng: {params.row.longitud}
                        </Typography>
                    </Box>
            },
			{
				field: 'tools',
				headerName: 'Resumen',
				width: 80,
                align: 'center',
				renderCell: (params) => {
                    return <IconButton>
                        <MoreHorizIcon onClick={(e) => this.seleccionarVulnerabilidad(params.row)  }  />
                        </IconButton>
				}
			},
			{
				field: 'delte',
				headerName: 'Eliminar',
				width: 80,
                align: 'center',
				renderCell: (params) => {
					return (<IconButton onClick={(e) => {
								this.handleDelete(params.row)
							}}>
							<DeleteOutlineIcon color="danger"/>
						</IconButton>);
				}
			},
        ]; //}}}
    }

    handleOnChangeFiltro(e) {
        this.setState({filtro: e.target.value});
        this.filtrar(e.target.value);
    }

    filtrar(valor) {
        const buscar = (valor || '').trim();
        if(this.timeout) window.clearTimeout(this.timeout);

        this.timeout = setTimeout(() => {
            this.setState({rows: buscar
                ? this.allData.filter(x => x.nombreMinuscula.includes(buscar))
                : this.allData
            });
        }, 500);
    }

    seleccionarVulnerabilidad(vulnerabilidad) {
        this.setState({vulnerabilidadSeleccionada: vulnerabilidad, infoVisible: true});
    }

    handleDelete(item) {
        if(!window.confirm('Eliminar este registro')) return;

        this.context.apidelete(`/vulnerabilidades/${item.id}`, true, {}, 'Registro eliminado').then(data => {
            this.cargarRegistros();
        });
    }


    cargarRegistros() {
        this.context.apiget('/vulnerabilidades', true).then(data => {
            this.allData = data.map((x,i) =>({...x}));
            this.setState({rows: this.allData});
        });
    }

    componentDidMount() {
        this.cargarRegistros();
    }

    render() {
		return <Box style={{display: 'flex', flexDirection: 'column', flex: 1}} spacing={1}>
                {this.state.opcionesDescargaVisible && <OpcionesDescarga
                        open={this.state.opcionesDescargaVisible}
                        onClose={e => { this.setState({opcionesDescargaVisible: false}) }} />}
                {this.state.infoVisible && <VulnerabilidadInfo vulnerabilidad={this.state.vulnerabilidadSeleccionada} open={this.state.infoVisible}
                    onClose={(e) => {this.setState({infoVisible: false})} }
                    />}
                <Paper sx={{mt: 1, p: 1}}  style={{display: 'flex', flexDirection: 'column', flex: 1}} elevation={8}>
    			    <Typography variant="h4" sx={{mb: 2}}>
                        Vulnerabilidades
                    </Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <TextField variant="outlined" fullWidth={true} value= {this.state.filtro} label="Buscar"
                                size="small" onChange={this.handleOnChangeFiltro}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">
                                            <IconButton onClick={(e) => {this.filtrar('')} }>
                                                <ClearIcon/>
                                            </IconButton>
                                        </InputAdornment>
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Button variant="contained" onClick={(e) => {this.setState({opcionesDescargaVisible: true})}  }>
                                Descargar
                            </Button>
                        </Grid>
                    </Grid>
                    <DataGrid style={{flex: 1}} sx={{mt: 1}} columns={this.columns} rows={this.state.rows} rowHeight={80}/>
                </Paper>
            </Box>;
    }
}
Vulnerabilidades.contextType = RestContext;

export default Vulnerabilidades;

