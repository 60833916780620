import { useState } from 'react';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { DashboardNavbar } from './dashboard-navbar';
import { DashboardSidebar } from './dashboard-sidebar';
import { Routes, Route } from 'react-router-dom';
import NotFound from '../modules/not-found';
import Dashboard from '../modules/dashboard';
import { isMobile } from 'react-device-detect';
import Amenazas from '../modules/amenazas';
import Vulnerabilidades from '../modules/vulnerabilidades';
import Catalogos from '../modules/catalogos';
import Danios from '../modules/catalogos/danios';
import TiposAmenaza from '../modules/catalogos/tipo-amenaza';
import TiposEvento from '../modules/catalogos/tipo-evento';
import Factores from '../modules/catalogos/factor';
import Parametros from '../modules/catalogos/parametro';
import Descriptores from '../modules/catalogos/descriptor';
import MapasOffline from '../modules/catalogos/mapa-offline';
import Mapas from '../modules/mapas';
import Usuarios from '../modules/catalogos/usuario';
import fondo from '../../assets/fondo.jpg';

const DashboardLayoutRoot = styled('div')(({ theme }) => ({
  display: 'flex',
  flex: '1 1 auto',
  maxWidth: '100%',
  paddingTop: 64,
  [theme.breakpoints.up('lg')]: {
    paddingLeft: 280
  }
}));

export const DashboardLayout = (props) => {
  const [isSidebarOpen, setSidebarOpen] = useState(!isMobile);

  const onClickItem = (props) => {
    if(isMobile && isSidebarOpen) 
      setSidebarOpen(false);
  };

  return (
    <div style={{flex: 1, display: "flex", flexDirection: "column", backgroundImage: `url(${fondo})`, backgroundSize: 'cover'  }}>
      <DashboardLayoutRoot
        style={{display: 'flex', flexDirection: 'column', flex: 1, margin: '10px'}}>
        <Box
          sx={{
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            width: '100%',
            height: '100%',
          }}
        >
          <Routes>
            <Route path="/" element={<Dashboard/>}/>
            <Route path="/amenazas/" element={<Amenazas/>}/>
            <Route path="/vulnerabilidades/" element={<Vulnerabilidades/>}/>
            <Route path="/catalogos/" element={<Catalogos/>}/>
            <Route path="/catalogos/danios" element={<Danios/>}/>
            <Route path="/catalogos/tipos-amenaza" element={<TiposAmenaza/>}/>
            <Route path="/catalogos/tipos-evento" element={<TiposEvento/>}/>
            <Route path="/catalogos/factores" element={<Factores/>}/>
            <Route path="/catalogos/parametros" element={<Parametros/>}/>
            <Route path="/catalogos/descriptores" element={<Descriptores/>}/>
            <Route path="/catalogos/mapas-offline" element={<MapasOffline/>}/>
            <Route path="/mapas" element={<Mapas/>}/>
            <Route path="/usuarios" element={<Usuarios/>}/>
            <Route path="*" element={<NotFound/>}/>
          </Routes>
        </Box>
      </DashboardLayoutRoot>
      <DashboardNavbar onSidebarOpen={() => setSidebarOpen(true)} />
      <DashboardSidebar
        onClose={() => setSidebarOpen(false)}
        onClickItem={onClickItem}
        open={isSidebarOpen}
      />
    </div>
  );
};
