import React from 'react';
import Avatar from '@mui/material/Avatar';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import { connect }  from 'react-redux';

import { RestContext } from '../../../contexts/RestContext';

const mapStateToProps = (state) => {
	return {
		urlImagen: state.user.urlImagen,
		sub: state.user.sub,
		nombre: state.user.nombre,
		escuelaProfesional: state.user.escuelaProfesional,
		escuelaProfesionalNombre: state.user.escuelaProfesionalNombre,
	}
};


class DashboardAlumno extends React.Component {
	constructor(props) {
		super(props);
		this.state = {alumno: {}};
        this.handleBoo1 = this.handleBoo1.bind(this);
        this.handleBoo2 = this.handleBoo2.bind(this);
	}

	componentDidMount() {
		/*
		this.context.apiget('/alumno', true).then(data => {
			this.setState({alumno: data});
		});*/
	}

    handleBoo1() {
        this.context.apiget('/alumnos/boo1', true).then(data => {
            console.log(data);
        });
    }

    handleBoo2() {
        this.context.apiget('/alumnos/boo2', true).then(data => {
            console.log(data);
        });
    }



	render() {
		return <div style={{flex: 0, display: 'flex', flexDirection: 'column'}}>
				<Paper style={{marginTop: 40, display: 'flex', flex: 1 , flexDirection: 'column', marginBottom: 60, padding: 20}}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={2}>
                            <Avatar className="md:mx-4" style={{width: '128px', height: '128px', margin: 'auto'}} src={this.props.urlImagen} />
                        </Grid>
                        <Grid item xs={12} md={10}>
                            <Typography variant="caption">Código</Typography>
                            <Typography variant="body2">{this.props.sub}</Typography>
                            <Typography variant="caption">Nombre</Typography>
                            <Typography variant="body2">{this.props.nombre}</Typography>
                        </Grid>
                    </Grid>
				</Paper>
		</div>;
	}
}
DashboardAlumno.contextType = RestContext;
export default connect(mapStateToProps)(DashboardAlumno);
