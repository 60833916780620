import React from 'react';
import AddIcon from '@mui/icons-material/Add';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { DataGrid, esES, GridToolbar } from '@mui/x-data-grid';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import NumberFormat from 'react-number-format';
import { RestContext } from '../../../../contexts/RestContext';
import Select from '@mui/material/Select';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import * as Yup from 'yup';
import { Formik, getIn } from 'formik';


class Editor extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            titulo: props.data?.codigo? `Editando registro ${props.data.codigo}`: 'Nuevo registro',
            initialValues: {
                codigo: '', nombre: '', orden: 0
            },
        };
        this.handleOnGuardar = this.handleOnGuardar.bind(this);
        this.handleOnCancelar = this.handleOnCancelar.bind(this);
    }

    componentDidMount() {
        if(this.props.data?.codigo) {
            this.context.apiget(`/amenazas/danios/${this.props.data.codigo}`, true).then(data => {
                this.setState(
                    {
                        initialValues: {...data}
                    }
                );
                this.forceUpdate();
            }, () => {
            });
        }
    }

    handleOnGuardar(values) {
        if(!window.confirm('Se va a guardar el registro, proceder?')) return;
        const payload = {...values};
        let p = null;
        if(this.props.data?.codigo) {
            p = this.context.apiput(`/amenazas/danios/${this.props.data.codigo}`, payload, true,
                                    'Registro guardado');
        }
        else {
            p = this.context.apipost(`/amenazas/danios`, payload, true,
                                    'Registro guardado');
        }

        p.then(data => this.props.onClose(data));
    }

    handleOnCancelar() {
        this.props.onClose();
    }

    render() { //{{{
        const validationSchema = Yup.object().shape({
            codigo: Yup.string().matches(/^[A-Z]{3}$/, 'Tres letras en mayúscula').required('Se requiere un código'),
            nombre: Yup.string().max(75, 'Máximo 75 caracteres').required('Ingrese el nombre'),
            orden: Yup.number('Se requiere un número').required().max(999)
        });

        return <Dialog open={this.props?.open} onClose={this.props?.onClose}>
                <DialogTitle>{this.props.data?.codigo? `Editando registro ${this.props.data.codigo}`: 'Nuevo registro'}</DialogTitle>
                <React.Fragment>
                    <Formik initialValues={this.state.initialValues} validationSchema={validationSchema}
                        enableReinitialize={true}
                        onSubmit={this.handleOnGuardar}>
                        {({
                            errors, handleBlur, handleChange, handleSubmit, isSubmitting,
                            isValid, dirty, touched, values
                        }) => (
                        <form autoComplete="off" noValidate onSubmit={handleSubmit}>
                            <DialogContent>
                                <Grid container spacing={2} sx={{mt: 1, mb: 1}}> {/*{{{ Grid de datos */}
                                    <Grid item xs={12} sm={6}>
                                        <TextField name="codigo"
                                            value={values.codigo}
                                            label="Código de usuario" size="small" fullWidth
                                            onChange={handleChange} onBlur={handleBlur}
                                            disabled={Boolean(this.props.data?.codigo)}
                                            error={Boolean(touched.codigo && errors.codigo)}
                                            helperText={touched.codigo && errors.codigo}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField name="orden"
                                            value={values.orden}
                                            label="Orden" size="small" fullWidth
                                            onChange={handleChange} onBlur={handleBlur}
                                            error={Boolean(touched.orden && errors.orden)}
                                            helperText={touched.orden && errors.orden}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField name="nombre"
                                            value={values.nombre}
                                            label="Nombre" size="small" fullWidth
                                            onChange={handleChange} onBlur={handleBlur}
                                            error={Boolean(touched.nombre && errors.nombre)}
                                            helperText={touched.nombre && errors.nombre}
                                        />
                                    </Grid>
                                </Grid> {/*}}}*/}
                            </DialogContent>
                            <DialogActions>
                                <Button color="primary" type="submit" disabled={!isValid}>
                                    Guardar
                                </Button>
                                <Button onClick={this.handleOnCancelar}>
                                    Cancelar
                                </Button>
                            </DialogActions>
                        </form>                  
                    )}
                    </Formik>
                </React.Fragment>
            </Dialog>
    } //}}}
}

Editor.contextType = RestContext;
export default Editor;
