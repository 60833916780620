import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { AppBar, Avatar, Box, IconButton, Toolbar } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import Typography from '@mui/material/Typography';
import { connect }  from 'react-redux';
import { useState, useContext } from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import { UserManager, WebStorageStateStore, Log } from "oidc-client-ts";
import { logout } from '../../actions';
import { RestContext } from '../../contexts/RestContext';
import ReactCountryFlag from "react-country-flag";

const mapStateToProps = (state) => {
   console.log(state.user);
  return { sub: state.user.sub }
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: dispatch(logout())
  }
};


const DashboardNavbarRoot = styled(AppBar)(({ theme }) => ({
  backgroundColor: 'rgba(0,0,0,0.5)',
  boxShadow: theme.shadows[3]
}));

export const DashboardNavbar = connect(mapStateToProps)( (props) => {
  const { onSidebarOpen, urlImagen, nombre, sub ,dispatch, ...other } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const menuOpen = Boolean(anchorEl);
  const [cookies, setCookie, removeCookie] = useCookies(['token', 'userid']);
  const context = useContext(RestContext);

  const history = useNavigate();

  const handleLogout = () => {
    removeCookie('sub');
    removeCookie('token');
    history('/auth');
  };


  const handleClose = function() {
    setAnchorEl(null);
  };

  const handleAvatarClick = function(event) {
    setAnchorEl(event.currentTarget);
  };



  return (
    <>
      <DashboardNavbarRoot
        sx={{
          left: {
            lg: 280
          },
          width: {
            lg: 'calc(100% - 280px)'
          },
        }}
        {...other}>
        <Toolbar
          disableGutters
          sx={{
            minHeight: 64,
            left: 0,
            px: 2
          }}
        >
          <IconButton
            onClick={onSidebarOpen}
            sx={{
              display: {
                xs: 'inline-flex',
                lg: 'none'
              }
            }}
          >
            <MenuIcon fontSize="small" />
          </IconButton>
          {/*
          <Tooltip title="Search">
            <IconButton sx={{ ml: 1 }}>
              <SearchIcon fontSize="small" />
            </IconButton>
          </Tooltip>
          */}
          <Box sx={{ flexGrow: 1 }} />
          {/*
          <Tooltip title="Contacts">
            <IconButton sx={{ ml: 1 }}>
              <UsersIcon fontSize="small" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Notifications">
            <IconButton sx={{ ml: 1 }}>
              <Badge
                badgeContent={4}
                color="primary"
                variant="dot"
              >
                <BellIcon fontSize="small" />
              </Badge>
            </IconButton>
          </Tooltip>
          */}
          <ReactCountryFlag countryCode={context.getTenant()} 
            style={{
                fontSize: '1.6em',
                lineHeight: '1.6em',
                marginRight: 10
             }}
          />
          <Box style={{display: 'flex', flexDirection: 'column'}}>  
              <Typography variant="caption" align="right" style={{color: 'white', textTransform: 'initial' }}>
                {sub} ({context.getTenant()})
              </Typography>
              <Typography variant="body2" align="right" style={{color: '#444444', textTransform: 'initial' }}>
                {nombre} 
              </Typography>
          </Box>  
          <Avatar
            sx={{
              height: 40,
              width: 40,
              ml: 1
            }}
            src={urlImagen}
            onClick={handleAvatarClick}
          >
          </Avatar>
          <Menu open={menuOpen} anchorEl={anchorEl} onClose={handleClose}>
            <MenuItem onClick={handleLogout}>Cerrar sesión</MenuItem>
          </Menu>
        </Toolbar>
      </DashboardNavbarRoot>
    </>
  );
});

DashboardNavbar.propTypes = {
  onSidebarOpen: PropTypes.func
};
