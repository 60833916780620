import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import { DashboardLayout } from './sys/components/dashboard-layout';
import Login from './auth/login';
import { Navigate, Routes, Route } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { connect } from 'react-redux';
import { setUsuario }  from './actions';
import PrivacyPolicy from './privacy-policy';
import Sys from './sys';

function mapDispatchToProps(dispatch) {
  return {
    setUsuario: usuario => dispatch(setUsuario(usuario))
  }
}

function App(props) {
  const [cookies] = useCookies(['token', 'username']);
  props.setUsuario({
      sub: cookies['username'],
  });

  return <div style={{display: 'flex', flexDirection: 'column', flex: 1}}>
        <Routes>
            <Route path="/auth" element={<Login/>}/>
            <Route path="/" element={<Sys/>}/>
            <Route path="/privacy-policy" element={<PrivacyPolicy/>}/>
            <Route path="/sys/*" element={<Sys/>}/>
        </Routes>
      </div>
}

export default connect(null, mapDispatchToProps)(App);
