import { DashboardLayout } from './components/dashboard-layout';
import { useCookies } from 'react-cookie';
import { setUsuario } from '../actions';
import { connect } from 'react-redux';
import React from 'react';
import { useEffect, useState } from 'react';
import Login from '../auth/login';

function mapDispatchToProps(dispatch) {
  return {
    setUsuario: (payload) => dispatch(setUsuario(payload))
  }
}

function Dashboard() {
  const [cookies] = useCookies(['token', 'userid']);
  const payload = {
      token: cookies['token'],
      userId: cookies['userId'],
  };

  if(!cookies['token']) return <Login/>
  return (
    <DashboardLayout>
    </DashboardLayout>
  )
}

export default connect(null, mapDispatchToProps)(Dashboard);
