import React from 'react';
import PropTypes from 'prop-types';
import { Box, Divider, Drawer, Typography, useMediaQuery } from '@mui/material';
import DashboardIcon from '@mui/icons-material/Dashboard';
import TableChartIcon from '@mui/icons-material/TableChart';
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import SchoolIcon from '@mui/icons-material/School';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import DeveloperBoardIcon from '@mui/icons-material/DeveloperBoard';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import CoPresentIcon from '@mui/icons-material/CoPresent';
import SummarizeIcon from '@mui/icons-material/Summarize';
import { connect } from 'react-redux';
import { NavItem } from './nav-item';
import logo120 from '../../assets/logo120.png';
import fondoSidenav from '../../assets/fondo_sidenav.jpg';
import logoborder from '../../assets/logoborder.png';
import FireplaceIcon from '@mui/icons-material/Fireplace';
import WavesIcon from '@mui/icons-material/Waves';
import FmdGoodIcon from '@mui/icons-material/FmdGood';
import { useEffect, useState } from 'react';

const items = [ //{{{
  {
    href: '/',
    icon: (<DashboardIcon />),
    title: 'Tablero',
    allowed: null,
  },
  {
    href: '/sys/amenazas',
    icon: (<FireplaceIcon  style={{color: 'rgb(100, 149, 237)'}} />),
    title: 'Amenazas',
    allowed: null,
  },
  {
    href: '/sys/vulnerabilidades',
    icon: (<WavesIcon  style={{color: 'rgb(128, 0, 0)'}} />),
    title: 'Vulnerabilidades',
    allowed: null,
  },
  {
    href: '/sys/mapas',
    icon: (<FmdGoodIcon  style={{color: 'rgb(128, 164, 0)'}} />),
    title: 'Mapa de incidencias',
    allowed: null,
  },
  {
    href: '/sys/catalogos',
    icon: (<DashboardIcon />),
    title: 'Catálogos',
    allowed: null,
  },
  {
    href: '/sys/usuarios',
    icon: (<DashboardIcon />),
    title: 'Usuarios',
    allowed: null,
  },
  /*  
  {
    href: '/sys/alumnos',
    icon: (<TableChartIcon fontSize="small" />),
    title: 'Alumnos',
    allowed: ['ACAD000001', 'ACAD000002', 'ACAD000003', ],
  },
  {
    href: '/sys/docentes',
    icon: (<CoPresentIcon fontSize="small" />),
    title: 'Docentes',
    allowed: ['ACAD000003', ],
  },
  {
    href: '/sys/matricula',
    icon: (<CalendarMonthIcon fontSize="small" />),
    title: 'Matrículas',
    allowed: ['ACAD000003', ],
  },
  */
    /*
  {
    href: '/sys/semestres',
    icon: (<CalendarMonthIcon fontSize="small" />),
    title: 'Semestres',
    allowed: ['ACAD000003', ],
  },*/
    /*
  {
    href: '/sys/escuelas-profesionales',
    icon: (<SchoolIcon fontSize="small" />),
    title: 'Escuelas profesionales',
    allowed: ['ACAD000003', ],
  },*/
]; //}}}

const mapStateToProps = (state) => {
  return { urlImagen: state.user.urlImagen, nombre: state.user.nombre, pps: state.user.pps }
};

export const DashboardSidebar = connect(mapStateToProps)( (props) => {
  const { open, onClose } = props;
  //const lgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'), {
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up('md'), {
    defaultMatches: true,
    noSsr: false
  });
  const [menuItems, setMenuItems] = useState([]);

  useEffect(() => {
    const newItems = [];
    for(let l of items) {
      if(l.allowed === null) {
        newItems.push(l);
        continue;
      }


      if(l.allowed.reduce((a,x) => ( a || (props.pps || []).includes(x) ), false)) {
          newItems.push(l);
          continue;
      } 
    }


    setMenuItems(newItems);
  }, []);


  const content = (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          /*      
          backgroundImage: `url(${fondoSidenav})`,
          backgroundSize: 'cover'*/
        }}
      >
        <div>
          <Box sx={{ p: 3, backgroundColor: 'neutral.900', minHeight: 200, display: 'block', textAlign: 'center' }}>
            <img src={logo120} alt="Logo UNIQ" style={{height: 100 }} />
            <Typography variant="body1" sx={{marginTop: 1}}>Sistema Kamayuq 2.0</Typography>
            <Typography variant="caption" sx={{marginTop: 1}}>Modulo Administrador</Typography>
          </Box>
        </div>
        <Box sx={{ flexGrow: 1, backgroundColor: 'neutral.900' }}>
          {/*<img src={logoborder} />*/}
          {menuItems.map((item) => (
            <NavItem
              onClick={props.onClickItem}
              key={item.title}
              icon={item.icon}
              href={item.href}
              title={item.title}
            />
          ))}
        </Box>
      </Box>
    </>
  );

  if (lgUp) {
    return (
      <Drawer
        anchor="left"
        open
        PaperProps={{
          sx: {
            backgroundColor: 'primary.main',
            color: '#FFFFFF',
            width: 280
          }
        }}
        variant="permanent"
      >
        {content}
      </Drawer>
    );
  }

  return (
    <Drawer
      anchor="left"
      onClose={onClose}
      open={open}
      PaperProps={{
        sx: {
          backgroundColor: 'neutral.900',
          color: '#FFFFFF',
          width: 280
        }
      }}
      sx={{ zIndex: (theme) => theme.zIndex.appBar + 100 }}
      variant="temporary"
    >
      {content}
    </Drawer>
  );
});

DashboardSidebar.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool
};
