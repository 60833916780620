import React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InsightsIcon from '@mui/icons-material/Insights';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import Paper from '@mui/material/Paper';
import shortid from 'shortid';
import Typography from '@mui/material/Typography';
import VisibilityIcon from '@mui/icons-material/Visibility';
import pinAmenaza from '../../../assets/pinamenaza.png';
import pinVulnerabilidad from '../../../assets/pinvulnerabilidad.png';
import AmenazaInfo from '../amenazas/info';
import VulnerabilidadInfo from '../vulnerabilidades/info';

import { connect }  from 'react-redux';

import { RestContext } from '../../../contexts/RestContext';

var map;
var google;

class Mapas extends React.Component {
    googleMapsKey = 'AIzaSyBsBAzIQNk60_d0eo8J8rBx4G-J4fnn5kg';
    mapRef = React.createRef();

	constructor(props) {
		super(props);
		this.state = {incidencias: [], tipoItem: '', itemSeleccionado: {}, dialogoAbierto: false, anchorEl: null, menuAbierto: false};
        this.initMap = this.initMap.bind(this);
        this.mostrarIncidencia = this.mostrarIncidencia.bind(this);
        this.toggleVerPoligono = this.toggleVerPoligono.bind(this);
        this.descargarKml = this.descargarKml.bind(this);
	}

	componentDidMount() { //{{{
        const callbackName = 'mapReady';
        const url = `https://maps.googleapis.com/maps/api/js?key=${this.googleMapsKey}&callback=${callbackName}`;
        if(document.querySelector(`script[src='${url}]'`) === null) {
            const script = document.createElement('script');
            script.async = true;
            script.src = url;
            window[callbackName] =  () => {
                google = window.google;
                map = new window.google.maps.Map(this.mapRef.current, {
                    zoom: 9,
                    center: {lat: -13, lng: -71},
                    streetViewControl: false,
                    mapTypeId: 'satellite',
                    mapTypeControlOptions: {
                        style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
                        position: google.maps.ControlPosition.BOTTOM_RIGHT,
                    },
                });
                this.initMap();
                //infoWindow = new google.maps.InfoWindow({content: ''});
            };
            document.head.appendChild(script);
        }
	} //}}}

    cargarPins(nuevosPins) { //{{{
        const incidencias = nuevosPins.map((x,i) => (
            {   id: shortid.generate(), innerId: x.id,
                ...x,
                marker: new window.google.maps.Marker({
                    position: new window.google.maps.LatLng(x.latitud, x.longitud),
                    map, icon: x.tipo == 'A'?pinAmenaza: pinVulnerabilidad,//title: x.nombre, tooltip: x.nombre, //icon
                }),
                polygon: null,
            }
        ));

        incidencias.forEach(x => {
            x.marker.addListener('click', (e) => {
                this.setState({tipoItem: x.tipo, itemSeleccionado: x, dialogoAbierto: true});
            });

            if(x.puntos_?.length) {
                x.polygon = new window.google.maps.Polygon({
                    paths: x.puntos_.map(x=>({lat: x.latitud, lng: x.longitud }))
                });
            }
        });

        this.setState({incidencias});

    } //}}}

    initMap() { //{{{
        this.context.apiget('/mapa/incidencias?incluir_puntos=true', true).then(data => {
            this.cargarPins(data);
        });
    } //}}}

    mostrarIncidencia(item, index) { //{{{
        map.setCenter({lat: item.latitud, lng: item.longitud});
        map.setZoom(14);
    } //}}}

    toggleVerPoligono() { //{{{
        this.setState({menuAbierto: false, anchorEl: null});
        if(!this.state.itemSeleccionado?.polygon) return;
        this.state.itemSeleccionado.polygon.setMap(
            this.state.itemSeleccionado.polygon.getMap()? null: map
        );
    } //}}}

    descargarKml() {
        this.setState({menuAbierto: false, anchorEl: null});
        if(!this.state.itemSeleccionado?.polygon) return;
        const p = this.state.itemSeleccionado;

        const kml=`<?xml version="1.0" encoding="UTF-8"?>
            <kml xmlns="http://www.opengis.net/kml/2.2" xmlns:gx="http://www.google.com/kml/ext/2.2"
                xmlns:kml="http://www.opengis.net/kml/2.2" xmlns:atom="http://www.w3.org/2005/Atom">
                <Document id="${p.id}" xsi:schemaLocation="http://www.opengis.net/kml/2.2
                    http://schemas.opengis.net/kml/2.2.0/ogckml22.xsd
                    http://www.google.com/kml/ext/2.2
                    http://code.google.com/apis/kml/schema/kml22gx.xsd">
                    <name>${p.tipo=='A'?'Amenaza':'Vulnerabilidad'}-${p.id}.kml</name>
                    <open>1</open>
                    <Style id="PolyStyle001">
                        <LabelStyle>
                            <color>00000000</color>
                            <scale>0</scale>
                        </LabelStyle>
                        <LineStyle>
                            <color>ffff0096</color>
                            <width>3</width>
                        </LineStyle>
                        <PolyStyle>
                            <color>33e84d00</color>
                        </PolyStyle>
                    </Style>
                    <StyleMap id="PolyStyle000">
                        <Pair>
                            <key>normal</key>
                            <styleUrl>#PolyStyle001</styleUrl>
                        </Pair>
                        <Pair>
                            <key>highlight</key>
                            <styleUrl>#PolyStyle00</styleUrl>
                        </Pair>
                    </StyleMap>
                    <Style id="PolyStyle00">
                        <LabelStyle>
                            <color>00000000</color>
                            <scale>0</scale>
                        </LabelStyle>
                        <LineStyle>
                            <color>ffff0096</color>
                            <width>3</width>
                        </LineStyle>
                        <PolyStyle>
                            <color>33e84d00</color>
                        </PolyStyle>
                    </Style>
                    <Placemark id="${p.innerId}_0000">
                        <name>${p.tipo=='A'?'Amenaza': 'Vulnerabilidad'}</name>
                        <Snippet maxLines="0"></Snippet>
                        <styleUrl>#PolyStyle000</styleUrl>
                        <description><![CDATA[ ${p.descripcion} ]]></description>
                        <MultiGeometry>
                            <Polygon>
                                <altitudeMode>relativeToGround</altitudeMode>
                                <outerBoundaryIs>
                                    <LinearRing>
                                        <coordinates>
                                            ${p.puntos_.map(x => x.longitud+','+x.latitud+',0').join(' ')}
                                            ${p.puntos_[0].longitud},${p.puntos_[0].latitud},0
                                        </coordinates>
                                    </LinearRing>
                                </outerBoundaryIs>
                            </Polygon>
                        </MultiGeometry>
                    </Placemark>
                </Document>
            </kml>`;
            const blob = new Blob([kml], {type: 'application/vnd.google-earth.kml+xml'});
            var a = window.document.createElement('a');
            a.href = window.URL.createObjectURL(blob);
            a.setAttribute('target', '_blank');
            a.download = `${p.tipo=='A'?'Amenaza':'Vulnerabilidad'}-${p.innerId}.kml`;
            document.body.appendChild(a);
            a.click();
            a.remove();
    }

	render() {
		return <div style={{flex: 1, display: 'flex', flexDirection: 'column'}}>
                {Boolean(this.state.tipoItem=='A') && <AmenazaInfo open={this.state.dialogoAbierto} amenaza={this.state.itemSeleccionado}
                    onClose={e => {this.setState({dialogoAbierto: false, tipoItem: ''})} } />}
                {Boolean(this.state.tipoItem=='V') &&  <VulnerabilidadInfo open={this.state.dialogoAbierto} vulnerabilidad={this.state.itemSeleccionado}
                    onClose={e => {this.setState({dialogoAbierto: false, tipoItem: ''})} } />}
				<Paper style={{display: 'flex', flex: 1 , flexDirection: 'column', padding: 20, backgroundColor: 'rgba(255, 255, 255, 0.92)' }}>
				    <Typography variant="h4">Mapa de incidencias</Typography>
                    <Grid container style={{display: 'flex', flex: 1}}>
                        <Grid item xs={12} md={8} style={{flex: 1, display: 'flex', flexDirection: 'column'}}>
                            <div style={{flex: 1}} ref={this.mapRef}>
                            </div>
                        </Grid>
                        <Grid item xs={12} md={4} style={{maxHeight: 500, overflow: 'auto'}}>
                            {Boolean(this.state.itemSeleccionado) && <Menu
                                open={this.state.menuAbierto} anchorEl={this.state.anchorEl}
                                onClose={(e) => {this.setState({anchorEl: null, menuAbierto: false} )}  }>
                                <MenuItem onClick={this.toggleVerPoligono}>
                                    <ListItemIcon><VisibilityIcon/></ListItemIcon>
                                    <ListItemText>Ver polígono</ListItemText>
                                </MenuItem>
                                <MenuItem onClick={this.descargarKml}>
                                    <ListItemIcon><FileDownloadIcon/></ListItemIcon>
                                    <ListItemText>Descargar KML</ListItemText>
                                </MenuItem>
                            </Menu>}
                            <List>
                                {this.state.incidencias.map((x,i) => <ListItem key={i} 
                                    secondaryAction={(x.puntos_?.length)? <IconButton
                                        onClick={e => { this.setState({anchorEl: e.target, menuAbierto: true, itemSeleccionado: x}) } } color="secondary" title="Menú de puntos">
                                        <MoreHorizIcon/></IconButton> : null}
                                    >
                                    <ListItemButton onClick={e => this.mostrarIncidencia(x, e)  }>
                                        <ListItemIcon>
                                            <img src={x.tipo=='A'?pinAmenaza:pinVulnerabilidad}/>
                                        </ListItemIcon>
                                        <ListItemText>
                                            <Typography>
                                                {x.tipo=='A'?'Amenaza':'Vulnerabilidad'}
                                                <Chip label={`id: ${x.id}`}/>
                                            </Typography>
                                            <Typography variant="caption" component="div">
                                                E: {x.este}
                                            </Typography>
                                            <Typography variant="caption" component="div">
                                                N: {x.norte}
                                            </Typography>
                                        </ListItemText>
                                     </ListItemButton>
                                </ListItem>)}
                            </List>
                        </Grid>
                    </Grid>
				</Paper>
		</div>;
	}
}
Mapas.contextType = RestContext;
export default Mapas;
