import React from 'react';
import {Component} from 'react';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EventNoteIcon from '@mui/icons-material/EventNote';
import Avatar from '@mui/material/Avatar';
import NumberFormat from 'react-number-format';
import Typography from '@mui/material/Typography';
import { RestContext } from '../../../contexts/RestContext';
import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import AccordionDetails from '@mui/material/AccordionDetails';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { connect } from 'react-redux';
import { parseISO, format } from 'date-fns';

const mapStateToProps = (state) => {
  return { pps: state.user.pps }
};


function withParams(Component: Component) {
	const p = props => <Component {...props} params={useParams()} />;
	p.displayName="xxx";

	return p;
}


var map;
var marker;
var google;
var infoWindow;
var miPosicion;

class VulnerabilidadInfo extends React.Component {
    googleMapsKey = 'AIzaSyBsBAzIQNk60_d0eo8J8rBx4G-J4fnn5kg';
    mapRef = React.createRef();

	constructor(props) {
		super(props);
		this.state = {vulnerabilidad: {}, semestres: [], seleccionado: 0, avanceAcademico: [],
			dialogoPersonales: false, verObservaciones: false,
			dialogoReniec: false, datosReniec: {},
			vulnerabilidadObservacion: {}, puedeEditar: (props.pps || []).includes('ACAD000003'),
			vulnerabilidadEscalaPagos: {}, detalleEscalaPagosOpen: false,
		};
        this.descargarFicha = this.descargarFicha.bind(this);
        this.descargarExcel = this.descargarExcel.bind(this);
        this.initMap = this.initMap.bind(this);
        this.cargarDatos = this.cargarDatos.bind(this);
	}



	componentDidMount() {
        this.cargarDatos();
        const callbackName = 'mapReady';
        const url = `https://maps.googleapis.com/maps/api/js?key=${this.googleMapsKey}&callback=${callbackName}`;
        if(document.querySelector(`script[src='${url}]'`) === null) {
            const script = document.createElement('script');
            script.async = true;
            script.src = url;
            window[callbackName] =  () => {
                google = window.google;
                map = new window.google.maps.Map(this.mapRef.current, {
                    zoom: 9,
                    center: {lat: this.props.vulnerabilidad.latitud, lng: this.props.vulnerabilidad.longitud},
                    streetViewControl: false,
                    mapTypeId: 'satellite',
                    mapTypeControlOptions: {
                        style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
                        position: google.maps.ControlPosition.BOTTOM_RIGHT,
                    },
                });
                this.initMap();
                infoWindow = new google.maps.InfoWindow({content: ''});
            };
            document.head.appendChild(script);
        }
	}

    initMap() {
        marker = new window.google.maps.Marker({
            position: {
                lat: this.props.vulnerabilidad.latitud,
                lng: this.props.vulnerabilidad.longitud,
            },
        });
        marker.setMap(map);
    }

    cargarDatos() {
        this.context.apiget(`/vulnerabilidades/info/${this.props.vulnerabilidad.id}`, true).then(data => {
            this.setState({vulnerabilidad: data});
        });
    }

    descargarExcel() {
        this.context.apiget(`/vulnerabilidades/${this.props.vulnerabilidad.id}/ficha/excel`, false).then(response => {
            const filename = 'ficha1.xlsx';
            response.blob().then(data => {
                var a = window.document.createElement('a');
                a.href = window.URL.createObjectURL(data);
                a.setAttribute('target', '_blank');
                a.download = filename;
                document.body.appendChild(a);
                a.click();
                a.remove();
            });
        });
    }


    descargarFicha() {
        this.context.apiget(`/vulnerabilidades/${this.props.vulnerabilidad.id}/ficha`, false).then(response => {
            const filename = 'ficha1.pdf';
            response.blob().then(data => {
                var a = window.document.createElement('a');
                a.href = window.URL.createObjectURL(data);
                a.setAttribute('target', '_blank');
                a.download = filename;
                document.body.appendChild(a);
                a.click();
                a.remove();
            });
        });
    }



	render() {

	return (<Dialog open={this.props.open} onClose={this.props.onClose}
        fullWidth={true} maxWidth="sm" sx={{maxHeight: 600}}>
        <DialogTitle>Información del registro</DialogTitle>
        <DialogContent>
            <Grid container spacing={2}> {/*{{{*/}
                <Grid item xs={7}> {/*{{{*/}
                    <Grid item container spacing={2}> {/*{{{*/}
                        <Grid item xs={6}>
                            <Typography variant="caption" >
                                Id
                            </Typography>
                            <Typography variant="body1">
                                {this.props.vulnerabilidad.id}
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant="caption" >
                                Fecha
                            </Typography>
                            <Typography variant="body1">
                                {
                                    this.state.vulnerabilidad.fechaRegistro?
                                    format(parseISO(this.state.vulnerabilidad.fechaRegistro), 'dd/MM/yyy')
                                    : '--'
                                }
                            </Typography>
                        </Grid>
                    </Grid> {/*}}}*/}
                    <Grid item container spacing={2}> {/*{{{*/}
                        <Grid item xs={6}>
                            <Typography variant="caption" >
                                Latitud
                            </Typography>
                            <Typography variant="body1">
                                {this.props.vulnerabilidad.latitud}
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography variant="caption" >
                                Longitud
                            </Typography>
                            <Typography variant="body1">
                                {this.props.vulnerabilidad.longitud}
                            </Typography>
                        </Grid>
                    </Grid> {/*}}}*/}
                    <Typography variant="caption" >
                        Dimensión
                    </Typography>
                    <Typography variant="body2">
                        {this.state.vulnerabilidad.dimensionNombre}
                    </Typography>
                    <Typography variant="caption" >
                        Factor
                    </Typography>
                    <Typography variant="body2">
                        {this.state.vulnerabilidad.factorNombre}
                    </Typography>
                    <Typography variant="caption">
                        Tipo de patrimonio
                    </Typography>
                    <Typography variant="body2">
                        {this.state.vulnerabilidad.tipoPatrimonioNombre}
                    </Typography>
                </Grid> {/*}}}*/}
                <Grid item xs={5} ref={this.mapRef} >
                </Grid>
            </Grid> {/*}}}*/}

            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <Typography variant="caption">Caracteristicas</Typography>
                    <Typography variant="body2">
                        {this.state.vulnerabilidad.caracteristicasBien}
                    </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Typography variant="caption">Descripción</Typography>
                    <Typography variant="body2">
                        {this.state.vulnerabilidad.descripcion}
                    </Typography>
                </Grid>
            </Grid>
            {!!this.state.vulnerabilidad?.descriptores_?.length && <>
                <Typography variant="h6">Descriptores</Typography>
                <List>
                    {this.state.vulnerabilidad.descriptores_.map((x,i) => <span key={i}>
                        <ListItem>
                            <ListItemText primary={x.descriptorNombre} secondary={x.parametroNombre} />
                        </ListItem>
                        <Divider style={{borderColor: '#7f7f7f'}}/>
                    </span>)}
                </List>
            </>}


            {!!this.state.vulnerabilidad?.fotografias_?.length && <>
                <Typography variant="h6">Fotografías</Typography>
                {this.state.vulnerabilidad.fotografias_.map((x,i) => <Card key={i}>
                    <Box sx={{display: 'flex'}}>
                        <CardMedia component="img" sx={{width: 64}} image={`data:image/jpg;base64,${x.data}`} />
                        <CardContent sx={{flex: '1'}}>
                            <Typography variant="body1">{x.descripcion}</Typography>
                            {/*
                            <Typography variant="caption">
                                {x.latitud},{x.longitud}
                            </Typography>
                            */}
                        </CardContent>
                    </Box>
                </Card>
                )}
            </>}
        </DialogContent>
        <DialogActions>
            <Button onClick={(e) => this.descargarExcel() }>
                Descargar excel
            </Button>
            <Button onClick={(e) => this.descargarFicha() } variant="contained">
                Descargar ficha
            </Button>
            <Button onClick={(e) => this.props.onClose() }>
                Cerrar
            </Button>
        </DialogActions>
	</Dialog>)
	}
}

VulnerabilidadInfo.contextType = RestContext;
export default connect(mapStateToProps)(withParams(VulnerabilidadInfo));
